import React from "react";
import RequestButton from "../RequestButton";

const ThyroidIssuesInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Thyroid Issues Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What are thyroid issues?</p>
        <p className="text-md text-gray-600 mb-12">
          Thyroid disorders occur when your thyroid gland doesn’t function the
          way it’s supposed to. The thyroid gland, located in your neck, is
          responsible for making thyroid hormone and regulating your body’s
          metabolism and other important body functions. Thyroid issues often
          cause unpleasant side effects or medical complications.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are common thyroid problems?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Some common thyroid problems you might encounter include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Hyperthyroidism: your body makes excess thyroid hormone</li>
          <li>
            Hypothyroidism: your thyroid gland makes too little thyroid hormone
          </li>
          <li>Goiter: thyroid gland enlargement</li>
          <li>Thyroid cancer: cancer in your thyroid gland</li>
          <li>Thyroiditis: thyroid gland swelling</li>
          <li>Thyroid nodules: thyroid gland lumps</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Women are more likely than men to experience a thyroid problem, as one
          in eight women develop it during their lifetime. Family history of
          thyroid disease also increases your risk of having it.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the symptoms of thyroid issues?
        </p>
        <p className="text-md text-gray-600 mb-3">
          The following signs and symptoms can indicate you have a thyroid
          disorder:
        </p>
        <p className="text-lg text-gray-700 mb-2">Hyperthyroidism</p>
        <p className="text-md text-gray-600 mb-3">
          If you have hyperthyroidism you might feel hot or sweaty, lose weight,
          have sleeping problems, or notice increases in appetite. You may feel
          irritable, have lighter menstrual periods, or experience muscle
          weakness, diarrhea, hand trembling, or bulging eyes.
        </p>
        <p className="text-lg text-gray-700 mb-2">Hypothyroidism</p>
        <p className="text-md text-gray-600 mb-3">
          With hypothyroidism you might feel cold often, gain weight, or
          experience weight gain, fatigue, depression, hair loss, constipation,
          muscle weakness, or dry skin. You may notice heavy menstrual bleeding,
          too.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Seeking treatment with the Sunstate Medical Associates team can offer
          you thyroid disease symptom relief.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How are thyroid problems diagnosed?
        </p>
        <p className="text-md text-gray-600 mb-12">
          To find out which thyroid treatment is right for you, your doctor
          reviews your medical history, examines your thyroid gland, and asks
          questions about your symptoms. They often recommend a blood test and
          ultrasound to diagnose your condition and rule out complications.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How are thyroid issues treated?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Your doctor might recommend you take medications, receive radioiodine
          treatment, or undergo thyroid surgery to treat specific thyroid
          conditions. If they suspect you require thyroid surgery, your provider
          refers out to highly qualified endocrinologists in your area.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Other endocrine diseases your Sunstate Medical Associates provider can
          screen for and treat include diabetes, adrenal gland problems,
          polycystic ovary syndrome, pituitary disorders, and growth hormone
          problems. Medications are often effective treatments for many of these
          disorders.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Don’t let thyroid issues reduce your quality of life or lead to
          complications. Schedule an appointment with Sunstate Medical
          Associates by phone or online today.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default ThyroidIssuesInfo;
