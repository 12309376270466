import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import ThyroidIssuesInfo from "../../components/services/ThyroidIssuesInfo";

const ThyroidIssues = () => {
  return (
    <Layout>
      <PageHeader text="Thyroid Issues" />
      <ServicesHeader>
        Thyroid issues can lead to unpleasant symptoms and serious complications
        when left untreated. At Sunstate Medical Associates in Lake Mary,
        Florida, the expert internal medicine physicians offer numerous thyroid
        treatments to optimize your health and quality of life. Schedule an
        appointment with Sunstate Medical Associates by phone or online at the
        first sign of thyroid disease symptoms.
      </ServicesHeader>
      <ThyroidIssuesInfo />
      <Conditions />
    </Layout>
  );
};

export default ThyroidIssues;
